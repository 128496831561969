import React from 'react';
import { Link } from 'react-router-dom';

const Terms = () => (
	<div>
		<h1>Terms of Service</h1>
		<p className="DateUpdated">
			<strong>Last Updated:</strong> August 1, 2020
		</p>
		<h2>Terms of Service</h2>
		<p>The Target Pace websites, related mobile applications and services (collectively, the "Services")
			by Element Theory LLC (the "Company", "we", "us", or "our"), subject to these Terms of Service (the "Terms") and in
			accordance with the <Link to="/legal/privacy">Privacy Policy</Link> (the "Privacy Policy"). More information about the Services
			may be found at <Link to="/">https://www.targetpace.com</Link>. You agree to comply with these Terms and any supplemental terms
			which the Company makes available to you on the Services which shall form part of the Terms. BY ACCESSING,
			USING OR UPLOADING OR DOWNLOADING ANY INFORMATION OR MATERIALS TO OR FROM THE SERVICES, OR BY INDICATING YOUR
			ASSENT TO THESE TERMS BY CREATING AN ACCOUNT, CLICKING "SIGN UP" OR ANY SIMILAR MECHANISM, YOU ARE AGREEING
			TO THESE TERMS. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT ACCESS OR USE THE SERVICES. This agreement
			was written in English. To the extent a translated version of the Terms conflict with the English version,
			the English version controls.</p>
		<p>If you access or use the Services on behalf of a company or other entity, you represent that you have
			authority to bind such entity and its affiliates to these Terms and that it is fully binding on them. In
			such case, the term "you" will refer to such entity and its affiliates. If you do not have authority, you
			may not access or use the Services. These Terms contain disclaimers of warranties and limitations on
			liability that may be applicable to you.</p>
		<p>The Services cannot be provided and the agreement described in these Terms of Service cannot be
			performed without the Company processing data about you, and other Services members, including your
			location data. Processing of the data you share with the Company, including location data, is essential to
			the Services which we provide and a necessary part of our performance of the agreement we have with you.</p>
		<p>Notice Regarding Dispute Resolution: These Terms contain provisions that govern how disputes between you and
			the Company are resolved, including an agreement to arbitrate, which will, with limited exception, require
			you to submit claims you have against us to binding and final arbitration and limit you to claims against
			the Company on an individual basis.</p>

		<h2>Registration and Accounts</h2>
		<p>The Services are intended solely for persons who are 13 years old or such higher age required in your country
			to use the Services. If you are under the legal age to form a binding contract in your jurisdiction, you
			represent that your parent or legal guardian has reviewed and agreed to these Terms on your behalf.</p>
		<p>To use the Services, you must register. You agree to: (a) provide true, accurate, current and complete
			information about yourself ("Member Data") and (b) maintain and promptly update the Member Data. You agree
			that the Company may use your Member Data to provide Services that you access or use and as otherwise set
			forth in these Terms. If you provide any Member Data that is inaccurate or not current, or the Company has
			reasonable grounds to suspect that such Member Data is inaccurate or not current, the Company has the right
			to suspend or terminate your account and refuse current or future use of the Services. In consideration of
			your use of the Services, you represent that you are not a person barred from receiving services under the
			laws of any applicable jurisdiction.</p>
		<p>You are solely responsible for maintaining the confidentiality of the password associated with your account
			and for restricting access to your password, your computer and mobile device while logged into the Services.
			You accept responsibility for all activities that occur under your account or from your computer and mobile
			devices. We endeavor to use reasonable security measures to protect against unauthorized access to your
			account. We cannot, however, guarantee absolute security of your account, your Content (as defined below) or
			the personal information you provide, and we cannot promise that our security measures will prevent
			third-party "hackers" from illegally accessing the Services or its contents. You agree to immediately notify
			the Company of any unauthorized use of your account or password, or any other breach of security, and
			confirm that you understand all risks of unauthorized access to Member Data and any other information or
			content you provide to the Company.</p>

		<h2>Member Content and Conduct</h2>
		<h3>Content</h3>
		<p>You own the information, data, text, software, sound, photographs, graphics, video, messages, posts, tags, or
			other materials you make available in connection with the Services ("Content"), whether publicly posted,
			privately transmitted, or submitted through a third party API (e.g. a photograph submitted via Instagram).
			You grant us a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use, reproduce, modify,
			adapt, publish, translate, create derivative works from, distribute, publicly perform and display any
			Content and any name, username or likeness that you post on or in connection with the Services in all media
			formats and channels now known or later developed without compensation to you. This license ends when you
			delete your Content or your account.</p>
		<p>You understand that you, and not the Company, are entirely responsible for all Content that you upload, post,
			email, transmit or otherwise make available via the Services. The Company does not proactively and routinely
			screen or monitor the Content posted to the Services by others and, as such, does not guarantee the
			accuracy, integrity or quality of such Content. The Company may, in its sole discretion, screen, monitor,
			refuse or remove any Content, or remove any Content that violates the Terms or is otherwise objectionable.
			You understand that by using the Services, you may be exposed to Content that is offensive, indecent or
			objectionable. Under no circumstances will the Company be liable in any way for any Content, including, but
			not limited to, any errors or omissions in any Content, or any loss or damage of any kind incurred as a
			result of the use of any Content made available on the Services. You agree to bear all risks associated with
			the use of any Content available in connection with the Services, including any reliance on the accuracy,
			completeness, or usefulness of such Content.</p>
		<p>You agree that the Company is not responsible for, and does not endorse, Content posted on the Services. If
			your Content violates these Terms, you may bear legal responsibility for that Content. As between you and
			the Company, any Content will be non-confidential and non-proprietary and we will not be liable for any use
			or disclosure of Content. You acknowledge and agree that your relationship with the Company is not a
			confidential, fiduciary, or other type of special relationship, and that your decision to submit any Content
			does not place the Company in a position that is any different from the position held by members of the
			general public, including with regard to your Content. Your Content will not be subject to any obligation of
			confidence on the part of the Company other than as set forth in our Privacy Policy and the Company will not
			be liable for any use or disclosure of any Content you provide.</p>
		<p>The Services may provide you with the ability to mark certain Content that you submit to the Services as
			private, public, or available only to select users of the Services. The Company will maintain the privacy of
			such Content in accordance with your elections. However, if you do not elect to mark your Content as private
			or available for a limited group of members, or later change such designation to allow such Content to be
			made available to anyone, you are responsible for the public nature of the content. Please review our
			Privacy Policy for more information on how to manage your privacy controls.</p>
		<h3>Conduct</h3>
		<p>The Services are for your personal and noncommercial use. You may not modify, copy, distribute, transmit,
			display, perform, reproduce, publish, license, create derivative works from, transfer or sell for any
			commercial purposes any portion of the Services, use of the Services or access to Content. You may not use
			the Services, or assist or encourage any other party, to engage in any of the following Prohibited
			Activities:</p>

		<ol>
			<li>Copying, framing or mirroring any part of the Services;</li>
			<li>Accessing the Services to monitor its availability, performance or functionality;</li>
			<li>Permitting any third party to access the Services;</li>
			<li>Using, copying, modifying, creating a derivative work of, reverse engineering, decompiling or otherwise
				attempting to extract the source code of the software underlying the Services or any part thereof,
				unless expressly permitted or required by law, and in any case, without providing prior written notice
				to the Company;</li>
			<li>Publishing, transmitting, distributing or storing content, material, information or data that: (1) is
				illegal, obscene, defamatory, threatening, harassing, abusive, or hateful or that advocates violence;
				(2) is harmful to or interferes with the Services or any third party’s networks, equipment,
				applications, services or websites (e.g., viruses, worms, Trojan horses, etc.); (3) infringes, dilutes,
				misappropriates or otherwise violates any privacy, intellectual property, publicity or other personal
				rights including, without limitation, copyrights, patents, trademarks, trade secrets or other
				proprietary information (including unauthorized use of domain names); or (4) is fraudulent or contains
				false, deceptive or misleading statements, claims or representations (such as "phishing");</li>
			<li>Attempting to disrupt, degrade, impair or violate the integrity or security of the Services or the
				computers, services, Accounts or networks of any other party (including, without limitation, "hacking,"
				"denial of service" attacks, etc.), including any activity that typically precedes attempts to breach
				security such as scanning, probing or other testing or vulnerability assessment activity, or engaging in
				or permitting any network or hosting activity that results in the blacklisting or other blockage of
				the Company internet protocol space;</li>
			<li>Committing any act that may be harmful to minors;</li>
			<li>Distributing, or disclosing any part of the Services in any medium, including without limitation by any
				automated or non-automated "scraping";</li>
			<li>Using any automated system, including, without limitation, "robots," "spiders," "offline readers," etc.,
				to access the Services in a manner that sends more request messages to the Company than a human can
				reasonably produce in the same period of time by using a conventional web browser;</li>
			<li>Taking any action that imposes, or may impose, at our sole discretion, an unreasonable or
				disproportionately large load on our infrastructure;</li>
			<li>Collecting or harvesting any information relating to an identified or identifiable individual, including
				account names and information about users of the Services, from the Services;</li>
			<li>Using the Services for any inappropriate commercial solicitation purposes;</li>
			<li>Accessing any content on the Services through any technology or means other than those provided or
				authorized by the Services;</li>
			<li>Submitting to the Services or to the Company any personally identifiable information, except as
				necessary for the establishment and operation of your account;</li>
			<li>Submitting to the Services or to the Company any information that may be protected from disclosure by
				applicable law;</li>
			<li>Bypassing the measure we may use to prevent or restrict access to the Services, including, without
				limitation, features that prevent or restrict use or copying of any content or enforce limitations on
				use of the Services or the content therein;</li>
			<li>Violating any applicable law, statute, ordinance or regulation, or encouraging any conduct that could
				constitute a criminal offense or give rise to civil liability;</li>
			<li>Removing any copyright, trademark or other proprietary rights notices contained in or on the Services;
				or</li>
			<li>Executing any form of network monitoring or running a network analyzer or packet sniffer or other
				technology to intercept, decode, mine or display any packets used to communicate between the Service’s
				servers or any data not intended for you.</li>
		</ol>

		<p>You are granted a limited, non-exclusive right to create a text hyperlink to the Services for non-commercial
			use only, provided such link does not portray the Company or any of its products or services in a false,
			misleading, derogatory or otherwise defamatory manner, and provided further that the linking site does not
			contain any pornographic, illegal, offensive, harassing or otherwise objectionable material. We reserve the
			right to revoke these licenses generally, or your right to use specific links, at any time, with or without
			cause.</p>
		<p>You understand that use of certain features of the Services may require you to purchase third party equipment
			or materials (e.g., GPS systems). While the Company may recommend the equipment or materials of certain
			third party suppliers, the Company shall have no responsibility for your acquisition or use of any third
			party equipment or materials and does not guarantee that third party equipment or materials will function
			with the Services or will be error-free.</p>
		<p>You understand that you are responsible for any charges associated with sending communications via your
			device. You hereby acknowledge that you have the right to communicate with your contacts via the Services.
		</p>
		<p>You represent and warrant that: (i) you are authorized to create your account, whether individually or on
			behalf of an organization; (ii) you own the Content posted by you on or through the Services or otherwise
			have the right to grant the rights and licenses set forth in these Terms; (iii) the posting and use of your
			Content on or through the Services does not and will not violate, misappropriate or infringe on the rights
			of any third party, including, without limitation, privacy and data protection rights, publicity rights,
			copyrights, trademark and/or other intellectual property rights; and (iv) you agree to pay for all
			royalties, fees, and any other monies owed by reason of Content you post on or through the Services.</p>

		<h2>Interactions with Members</h2>
		<p>The Services function as a venue to connect members in a virtual information place. As a neutral facilitator,
			the Company is not directly involved in the actual interactions between members of the Services. As a
			result, the Company has no control over the truth, accuracy, quality, legality, or safety of postings made
			by members of the Services. The Company shall have no responsibility to confirm the identity of members.
			The Company shall also have no responsibility to confirm or verify the qualifications, background, or
			abilities of members of the Services. You shall at all time exercise common sense and good judgment when
			dealing with any member of the Services.</p>
		<p>If you elect to use our features to inform your contacts about the Services, track your activities via the
			Services, or share your information with others, the Company may require you to provide contact information.
			the Company may contact that friend via a one-time email or text message. You represent that you are
			authorized to provide any third party contact information that you provide to the Company, that you are
			authorized to use such information to contact (including for the Company to contact on your behalf) the
			third party and that the Company may process it pursuant to the Privacy Policy.</p>

		<h2>Third Parties</h2>
		<p>Third party products and services made available on the Services are made and offered directly by the
			applicable third party. When you pursue or purchase any such product or service, you acknowledge that you
			are contracting directly with such third party and not with the Company. Your interaction with, or
			participation in promotions of, third parties found on or through the Services, including payment and
			delivery of goods or services, and any other terms, are solely between you and such third party. You are not
			obligated to use or transact business with any third party that appears on the Services. YOU AGREE THAT
			THE COMPANY SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGES OF ANY KIND INCURRED BY YOU AS THE RESULT OF ANY OF
			YOUR DEALINGS WITH THIRD PARTY SERVICE PROVIDERS OR ADVERTISERS AVAILABLE THROUGH THE SERVICES.</p>
		<p>The Company or third parties may provide links to other internet sites or resources through the Services.
			The Company does not endorse and is not responsible or liable for any content, information, advertising,
			products or other materials on or available from such sites or resources. You acknowledge and agree that
			the Company is not responsible for the availability of such external sites or resources.</p>

		<h2>Electronic Communications</h2>
		<p>The Services may provide you with the ability to send or post messages to forums or chat rooms, speak via
			internet voice connections or send similar messages and communications to third party service providers,
			advertisers, your personal contacts, other members and/or the Company. You agree to use communication
			methods available on the Services only to send communications and materials related to the subject matter
			for which the Company provided the communication method, and you further agree that all such communications
			by you shall be deemed your Content and shall be subject to and governed by the Terms and applicable law
			(including laws regulating direct marketing communications with which you will need to comply with, as
			applicable). By using communications methods available on the Services, you agree that (a) all
			communications methods constitute public, and not private, means of communication between you and the other
			party or parties, (b) communications sent to or received from third party service providers, advertisers or
			other third parties are not be endorsed, sponsored or approved by the Company (unless expressly stated
			otherwise by the Company) and (c) communications are not routinely pre-reviewed, post-reviewed, screened,
			archived or otherwise monitored by the Company in any manner, though the Company reserves the right to do so
			at any time at its sole discretion. You agree that all notices, disclosures and other communications that we
			provide to you electronically shall satisfy any legal requirement that such communications be in writing.
		</p>

		<h2>Proprietary Rights</h2>
		<p>You acknowledge and agree that the Services, any necessary software used in connection with the Services (if
			any), any aggregated data based on Content on the Services, and any Content available or made available on
			the Services contain proprietary and confidential information that is protected by applicable intellectual
			property and other laws. Except as expressly permitted by applicable law or authorized by the Company or
			applicable third party service providers or advertisers, you agree not to modify, rent, lease, loan, sell,
			distribute or create derivative works based on the Services, the software or Content available on the
			Services (other than Content that you may submit), in whole or in part.</p>
		<p>The Company grants you a personal, revocable, non-transferable, non-sublicensable and non-exclusive right and
			license to access and use the Services; provided that you do not (and do not allow any third party to) copy,
			modify, create a derivative work from, reverse engineer, reverse assemble or otherwise attempt to discover
			any source code, sell, assign, sublicense, grant a security interest in or otherwise transfer any right in
			the Services, except where permitted by law. You agree not to access the Services by any means other than
			through the interfaces that are provided by the Company.</p>
		<p>The term Target Pace, the Target Pace logo and other Target Pace logos and product and service names are the
			exclusive trademarks of, and are owned by, the Company, and you may not use or display such trademarks
			in any manner without the Company’s prior written permission. Any third party trademarks or service marks
			displayed on the Services are the property of their respective owners.</p>
		<p>The Company reserves all rights not expressly granted hereunder.</p>

		<h2>Claims of Infringement</h2>
		<p>The Digital Millennium Copyright Act (the "DMCA") provides recourse for copyright owners who believe that
			material appearing on the internet infringes their rights under U.S. copyright law. The Company also reviews
			claims of trademark infringement. If you believe in good faith that materials hosted by the Company infringe
			your copyright or trademark rights, you (or your agent) may send us a notice requesting that the material be
			removed, or access to it blocked. The notice must include the following information: (a) a physical or
			electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is
			allegedly infringed; (b) identification of the copyrighted work or trademark claimed to have been infringed
			(or, if multiple copyrighted works or trademarks located on the Services are covered by a single
			notification, a representative list of such works); (c) identification of the material that is claimed to be
			infringing or the subject of infringing activity, and information reasonably sufficient to allow the Company
			to locate the material on the Services; (d) the name, address, telephone number, and e-mail address of the
			complaining party; (e) a statement that the complaining party has a good-faith belief that
			use of the material in the manner complained of is not authorized by the copyright or trademark owner, its
			agent, or the law; and (f) a statement that the information in the notification is accurate and, under
			penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive
			right that is allegedly infringed. Please be advised that the Company will not respond to complaints that do
			not meet these requirements. If the Company determines that the materials alleged to infringe your copyright
			or trademark rights do not require removal, the Company will remove those materials only pursuant to a court
			order declaring the content or use of the materials unlawful.</p>
		<p>If you believe in good faith that a notice of copyright infringement has been wrongly filed against you, the
			DMCA permits you to send us a counter-notice. Counter-notices must include the following information: (a)
			your name, address, and telephone number; (b) the source of the content that was removed; (c) a statement
			under penalty of perjury that you have a good-faith belief that the content was removed in error; (d) a
			statement that you consent to the jurisdiction of Federal District Court for the judicial district in which
			your address is located; or, if your address is outside of the United States, for any judicial district in
			which the Company may be found, and that you will accept service of process from the person who provided the
			original complaint; and (e) a physical or electronic signature (for example, typing your full name).</p>
		<p>Notices and counter-notices with respect to the Services must meet then-current statutory requirements
			imposed by the DMCA (see <a href="https://copyright.gov/title17/92appb.html" target="_blank">https://copyright.gov/title17/92appb.html</a>)
			and should be sent to the agent identified below. We suggest that you consult your legal advisor before filing
			a notice or counter-notice. Also, be aware that there may be penalties for false claims under the DMCA.</p>
		<p>Agent to Receive Notices of Claimed Infringement:</p>
		<p>
			support@targetpace.com
		</p>
		<p>with the subject line: "Target Pace: Intellectual Property".</p>

		<h2>Your Feedback</h2>
		<p>We welcome your comments, feedback, suggestions, and other communications regarding the Services and the
			information and services we make available through the Services (collectively, "Feedback"). If you provide
			Feedback, you hereby grant to the Company a worldwide, non-exclusive, transferable, assignable,
			sub-licensable, perpetual, irrevocable, royalty-free license to copy, distribute, create derivative works
			of, publicly display and perform and otherwise exploit such Feedback and to use, make, have made, sell,
			offer for sale, import and export products and services based on such Feedback. For this reason, we ask that
			you not send the Company any Feedback that you do not wish to license to us as set forth above.</p>

		<h2>Disclaimer of Warranties and Liability</h2>
		<p>THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND CONTENT AVAILABLE THROUGH THE SERVICES ARE PROVIDED TO YOU
			"AS IS" AND WITHOUT WARRANTY. THE COMPANY AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS,
			PARTNERS AND LICENSORS HEREBY DISCLAIM ALL WARRANTIES WITH REGARD TO SUCH INFORMATION, SOFTWARE, PRODUCTS,
			SERVICES AND CONTENT, INCLUDING, WITHOUT LIMITATION, ALL IMPLIED WARRANTIES AND CONDITIONS OF
			MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT. THE COMPANY AND ITS
			SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS MAKE NO WARRANTY THAT: (a) THE
			SERVICE WILL MEET YOUR REQUIREMENTS; (b) YOUR CONTENT WILL BE AVAILABLE OR THE SERVICE WILL BE
			UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; (c) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
			SERVICE WILL BE ACCURATE OR RELIABLE; (d) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION OR OTHER
			MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS; AND (e) ANY ERRORS IN
			THE SERVICES WILL BE CORRECTED.</p>
		<p>YOU EXPRESSLY AGREE THAT THE COMPANY IS NOT PROVIDING MEDICAL ADVICE VIA THE SERVICES. THE CONTENT PROVIDED
			THROUGH THE SERVICES, INCLUDING ALL TEXT, PHOTOGRAPHS, IMAGES, ILLUSTRATIONS, GRAPHICS, AUDIO, VIDEO AND
			AUDIO-VIDEO CLIPS, AND OTHER MATERIALS, WHETHER PROVIDED BY US OR BY OTHER ACCOUNT HOLDERS OR THIRD PARTIES
			IS NOT INTENDED TO BE AND SHOULD NOT BE USED IN PLACE OF (a) THE ADVICE OF YOUR PHYSICIAN OR OTHER MEDICAL
			PROFESSIONALS, (b) A VISIT, CALL OR CONSULTATION WITH YOUR PHYSICIAN OR OTHER MEDICAL PROFESSIONALS, OR (c)
			INFORMATION CONTAINED ON OR IN ANY PRODUCT PACKAGING OR LABEL. SHOULD YOU HAVE ANY HEALTH RELATED QUESTIONS,
			PLEASE CALL OR SEE YOUR PHYSICIAN OR OTHER HEALTHCARE PROVIDER PROMPTLY. SHOULD YOU HAVE AN EMERGENCY, CALL
			YOUR PHYSICIAN OR 911 IMMEDIATELY. YOU SHOULD NEVER DISREGARD MEDICAL ADVICE OR DELAY IN SEEKING MEDICAL
			ADVICE BECAUSE OF ANY CONTENT PRESENTED ON THE SERVICES, AND YOU SHOULD NOT USE THE SERVICES OR ANY CONTENT
			ON THE SERVICES FOR DIAGNOSING OR TREATING A HEALTH PROBLEM. THE TRANSMISSION AND RECEIPT OF OUR CONTENT, IN
			WHOLE OR IN PART, OR COMMUNICATION VIA THE INTERNET, E-MAIL OR OTHER MEANS DOES NOT CONSTITUTE OR CREATE A
			DOCTOR-PATIENT, THERAPIST-PATIENT OR OTHER HEALTHCARE PROFESSIONAL RELATIONSHIP BETWEEN YOU AND THE COMPANY.
		</p>
		<p>YOU EXPRESSLY AGREE THAT YOUR ATHLETIC ACTIVITIES (INCLUDING, BUT NOT LIMITED TO, AEROBIC EXERCISE, ANAEROBIC
			EXERCISE, OR FOLLOWING A TRAINING PLAN BY THE COMPANY OFFERED ON THE SERVICES) CARRY CERTAIN INHERENT AND
			SIGNIFICANT RISKS OF PROPERTY DAMAGE, BODILY INJURY OR DEATH AND THAT YOU VOLUNTARILY ASSUME ALL KNOWN AND
			UNKNOWN RISKS ASSOCIATED WITH THESE ACTIVITIES EVEN IF CAUSED IN WHOLE OR PART BY THE ACTION, INACTION OR
			NEGLIGENCE OF THE COMPANY OR BY THE ACTION, INACTION OR NEGLIGENCE OF OTHERS.</p>
		<p>YOU EXPRESSLY AGREE THAT THE COMPANY DOES NOT ASSUME RESPONSIBILITY FOR THE INSPECTION, SUPERVISION,
			PREPARATION, OR CONDUCT OF ANY RACE, CONTEST, CHALLENGE, OR GROUP ACTIVITY THAT UTILIZES THE SERVICES,
			INCLUDING ANY THAT ARE ORGANIZED BY A CLUB ADMINISTRATOR.</p>
		<p>YOU EXPRESSLY AGREE TO RELEASE THE COMPANY, ITS SUBSIDIARIES, AFFILIATES, OFFICERS, AGENTS, REPRESENTATIVES,
			EMPLOYEES, PARTNERS AND LICENSORS (THE "RELEASED PARTIES") FROM ANY AND ALL LIABILITY IN CONNECTION WITH
			YOUR ATHLETIC ACTIVITIES AND/OR USE OF THE COMPANY WEBSITES, MOBILE APPLICATIONS, CONTENT, SERVICES OR
			PRODUCTS (INCLUDING, WITHOUT LIMITATION, ANY TRAINING PLAN BY THE COMPANY), AND PROMISE NOT TO SUE THE RELEASED
			PARTIES FOR ANY CLAIMS, ACTIONS, INJURIES, DAMAGES, OR LOSSES ASSOCIATED WITH SUCH USE. YOU ALSO AGREE THAT
			IN NO EVENT SHALL THE RELEASED PARTIES BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
			PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH (a) YOUR
			USE OR MISUSE OF THE SERVICES, (b) YOUR USE OR MISUSE OF EQUIPMENT OR PROGRAMS CREATED OR LICENSED BY THE COMPANY
			(INCLUDING, WITHOUT LIMITATION, ANY TRAINING PLAN BY THE COMPANY) WHILE ENGAGED IN ATHLETIC ACTIVITIES,
			(c) YOUR DEALINGS WITH THIRD-PARTY SERVICE PROVIDERS OR ADVERTISERS AVAILABLE THROUGH THE SERVICES, (d) ANY
			DELAY OR INABILITY TO USE THE SERVICES EXPERIENCED BY YOU, OR (e) ANY INFORMATION, SOFTWARE, PRODUCTS,
			SERVICES OR CONTENT OBTAINED THROUGH THE SERVICES, WHETHER BASED ON CONTRACT, TORT, STRICT LIABILITY OR
			OTHERWISE, EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES.</p>
		<p>BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW SOME OR ALL OF THE EXCLUSIONS OR LIMITATIONS OF LIABILITY
			ABOVE, SUCH AS EXCLUSIONS FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SOME OF THE ABOVE LIMITATIONS OR
			EXCLUSIONS MAY NOT APPLY TO YOU. IN ENTERING INTO THIS RELEASE, YOU ACKNOWLEDGE THAT YOU HAVE READ AND
			UNDERSTAND, AND HEREBY EXPRESSLY WAIVE THE BENEFITS OF, SECTION 1542 OF THE CIVIL CODE OF CALIFORNIA, (AND
			ANY SIMILAR LAW OF ANY STATE, COUNTRY OR TERRITORY), WHICH PROVIDES AS FOLLOWS: "A GENERAL RELEASE DOES NOT
			EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING
			THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR."</p>
		<p>THE COMPANY DOES NOT ENDORSE YOUR CONTENT OR ANY OTHER MEMBER’S CONTENT AND SPECIFICALLY DISCLAIMS ANY
			RESPONSIBILITY OR LIABILITY TO ANY PERSON OR ENTITY FOR ANY LOSS, DAMAGE (WHETHER ACTUAL, CONSEQUENTIAL,
			INDIRECT, PUNITIVE OR OTHERWISE), INJURY, CLAIM, LIABILITY OR OTHER CAUSE OF ANY KIND OR CHARACTER BASED
			UPON OR RESULTING FROM ANY CONTENT ON THE SERVICES.</p>
		<p>THE COMPANY’S MAXIMUM AGGREGATE LIABILITY TO YOU PURSUANT TO THESE TERMS SHALL NOT EXCEED THE AMOUNT
			OF FEES PAID BY YOU TO THE COMPANY IN THE 12-MONTHS PRIOR TO DATE THE CLAIM.</p>

		<h2>Indemnity</h2>
		<p>You agree to indemnify and hold the Company and its subsidiaries, affiliates, officers, agents,
			representatives, employees, partners and licensors harmless from any claim or demand, including reasonable
			attorneys’ fees, made by any third party due to or arising out of Content you submit, post, transmit or
			otherwise seek to make available through the Services, your use of the Services, your athletic activities
			which generate the Content you post or seek to post on the Services (including, but not limited to, athletic
			activities in connection with any contests, races, group rides, or other events which the Company sponsors,
			organizes, participates in, or whose Services are used in connection with), your connection to the Services,
			your violation of the Terms, your violation of any data protection or privacy laws, or your violation of any
			rights of another person or entity. Your rights with respect to the Company are not modified by the
			foregoing indemnification if the laws of your country of residence, applicable as a result of your use of
			our Services, do not permit it.</p>

		<h2>Notice for California Members</h2>
		<p>Under California Civil Code Section 1789.3, California Services members are entitled to the following
			specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the
			California Department of Consumer Affairs may be contacted in writing at 1625 N. Market Blvd., Suite N 112,
			Sacramento, California 95834, or by telephone at (800) 952-5210.</p>

		<h2>Dispute Resolution</h2>
		<h3>Arbitration</h3>
		<p>The parties shall use their best efforts to settle any dispute, claim, question, or disagreement directly
			through good-faith negotiations, which shall be a precondition to either party initiating a lawsuit or
			arbitration. Except for disputes relating to the Company’s intellectual property (such as trademarks,
			trade dress, domain names, trade secrets, copyrights, and patents), all claims arising out of or relating to
			this Agreement and your use of the Services shall be finally settled by binding arbitration administered by
			JAMS in accordance with its provisions and procedures for consumer-related disputes, excluding any rules or
			procedures governing or permitting class actions. The arbitrator, and not any court or agency, shall have
			exclusive authority to resolve all disputes arising out of or relating to this Agreement, including, but not
			limited to, any claim that all or any part of this Agreement is void or voidable. The arbitrator shall be
			empowered to grant whatever relief would be available in a court; provided, however, that the arbitrator
			will not have authority to award damages, remedies, or awards that conflict with this Agreement. The
			arbitrator’s award shall be binding on the parties and may be entered as a judgment in any court of
			competent jurisdiction. To the extent the filing fee for the arbitration exceeds the cost of filing a
			lawsuit, the Company will pay the additional cost. You and the Company hereby expressly waive trial by jury. You
			also agree not to participate in claims brought in a private attorney general or representative capacity, or
			consolidated claims involving another person’s account, if the Company is a party to the proceeding. This
			dispute resolution provision will be governed by the Federal Arbitration Act.</p>
		<p>The parties understand that, absent this mandatory provision, they would have the right to sue in court and
			have a jury trial. They further understand that, in some instances, the costs of arbitration could exceed
			the costs of litigation and that the right to discovery may be more limited in arbitration than in court.
		</p>
		<h3>Class-Action Waiver</h3>
		<p>The parties further agree that any arbitration shall be conducted in their individual capacities only, and
			not as a class action or other representative action. If any court or arbitrator determines that the
			class-action waiver set forth in this section is void or unenforceable for any reason, or that an
			arbitration can proceed on a class basis, then the arbitration provision set forth above shall be deemed
			null and void in its entirety and the parties shall be deemed to have not agreed to arbitrate disputes.</p>
		<h3>Exception—Litigation of Small Claims Court Claims</h3>
		<p>Notwithstanding the parties’ decision to resolve all disputes through arbitration, either party may also seek
			relief in a small claims court for disputes or claims within the scope of that court’s jurisdiction.</p>
		<h3>Time Limitation on Claims</h3>
		<p>You agree that any claim you may have arising out of or related to your relationship with the Company and
			these Terms must be filed within one year after such claim arose; otherwise, your claim is permanently
			barred.</p>

		<h2>Choice of Law and Forum</h2>
		<p>Any action related to the Terms, Content, the Services, and your relationship with the Company shall be
			governed by, and construed and interpreted in accordance with, the laws of the State of New York without
			regard to its conflict of laws principles AND WILL SPECIFICALLY NOT BE GOVERNED BY THE UNITED NATIONS
			CONVENTIONS ON CONTRACTS FOR THE INTERNATIONAL SALE OF GOODS, IF OTHERWISE APPLICABLE. Subject to the
			Section entitled "Dispute Resolution", the parties irrevocably consent to bring any action to enforce this
			Agreement in the federal or state courts embracing New York, New York and you consent to the exclusive
			jurisdiction of the federal or state courts embracing New York, New York. If any party hereto brings any
			suit or action against another for relief, declaratory or otherwise, arising out of these Terms, the
			prevailing party will have and recover against the other party, in addition to all court costs and
			disbursements, such sum as the court may adjudge to be reasonable attorneys’ fees. Except to the extent
			prohibited by applicable law, the parties agree that any claim or cause of action arising out of or related
			to use of the Services or the Terms must be filed within one (1) year after such claim or cause of action
			arose or be forever barred.</p>

		<h2>United States Operation</h2>
		<p>This Services are controlled by the Company from its offices within the United States of America. The Company
			makes no representation that the Content or the Services are appropriate or available for use in other
			locations. Access to or use of the Content or the Services from territories where such access or use is
			illegal is prohibited. Those who choose to access the Services from locations outside of the United States
			do so on their own initiative and are responsible for compliance with applicable local laws. You may not use
			or export the Content in violation of U.S. export laws and regulations.</p>

		<h2>Termination</h2>
		<p>You agree that the Company may, under certain serious circumstances and without prior notice, immediately
			terminate your account and/or access to the Services. Cause for such termination shall include, but not be
			limited to, (a) breaches or violations of the Terms or other incorporated agreements, policies or
			guidelines, (b) requests by law enforcement or other government agencies, (c) a request by you
			(self-initiated account deletions), (d) discontinuance or material modification to the Services (or any
			portion thereof), (e) unexpected technical or security issues or problems, (f) extended periods of
			inactivity, and/or (g) nonpayment of any fees owed by you in connection with the Services. Termination of
			your account may include (x) removal of access to all offerings within the Services, (y) deletion of your
			information, files and Content associated with your account, and (z) barring of further use of the Services.
			Further, you agree that all terminations for cause shall be made in the Company’s sole discretion and that
			the Company shall not be liable to you or any third party for any termination of your account or access to
			the Services. The following Sections shall survive termination of your account and/or the Terms: Member
			Content Submitted to the Services, Proprietary Rights, Your Feedback, Disclaimer of Warranties and
			Liability, Indemnity, Applicable Laws and General.</p>

		<h2>General</h2>
		<p>You agree that no joint venture, partnership, joint controllership, employment or agency relationship exists
			between you and the Company as a result of the Terms or your use of the Services. The Terms constitute the
			entire agreement between you and the Company with respect to your use of the Services. The failure of the
			Company to exercise or enforce any right or provision of the Terms shall not constitute a waiver of such right
			or provision. If any provision of the Terms is found by a court of competent jurisdiction to be invalid, the
			parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as
			reflected in the provision, and the other provisions of the Terms remain in full force and effect. You may
			not assign, delegate or otherwise transfer your account or your obligations under these Terms without the
			prior written consent of the Company. The Company has the right, in its sole discretion, to transfer or
			assign all or any part of its rights under these Terms and will have the right to delegate or use third
			party contractors to fulfill its duties and obligations under these Terms and in connection with the
			Services. The Company’s notice to you via email, regular mail or notices, posts, or links on the Services
			shall constitute acceptable notice to you under the Terms. A printed version of the Terms and of any notice
			given in electronic form shall be admissible in judicial or administrative proceedings based upon or
			relating to the Terms to the same extent and subject to the same conditions as other business documents and
			records originally generated and maintained in printed form. Section titles and headings in the Terms are
			for convenience only and have no legal or contractual effect. Any rights not expressly granted herein are
			reserved.</p>

		<h2>Modification of the Terms and Services</h2>
		<p>Certain provisions of the Terms may be supplemented or superseded by expressly designated legal notices or
			terms located on particular pages on the Services. The Company reserves the right to update the Terms at any
			time and for any reason in its sole discretion. The Company will notify you of any material changes to the
			Terms or to any service or other features of the Services. By continuing to access or use the Services after
			we have provided you with notice of a modification, you are agreeing to be bound by the modified Terms. If
			the modified Terms are not acceptable to you, your only recourse is to cease using the Services.</p>
		<p>The Company and its third party service providers may make improvements and/or changes in the Services,
			products, services, mobile applications, features, programs, and prices described at any time and for any
			reason in its sole discretion. The mobile application may download and install upgrades, updates and
			additional features in order to improve, enhance, and further develop the Services. The Company reserves the
			right at any time to modify or discontinue, temporarily or permanently, the Services or any portion thereof
			with or without notice. You agree that the Company shall not be liable to you or to any third party for any
			modification, suspension or discontinuance of the Services.</p>
	</div>
);

export default Terms;
