import React from 'react';
import { Link } from 'react-router-dom';

// Style
import style from './index.module.css';

const Footer = () => (
	<div className={style.Footer}>
		<div className={style.FooterWrap}>
			<div className={style.FooterLinks}>
				<Link to="/legal/cookie">Cookie</Link>
				<Link to="/legal/privacy">Privacy</Link>
				<Link to="/legal/terms">Terms</Link>
			</div>
			<span className={style.Copyright}>&copy; 2020 Target Pace from Element Theory</span>
		</div>
	</div>
);

export default Footer;
