import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga';

// Components
import Footer from 'components/Footer';
import Header from 'components/Header';

// Container
import Home from 'containers/Home';
import Legal from 'containers/Legal';

// Style
import style from './index.module.css';

ReactGA.initialize('G-80NJK17P40', {
	debug: false, // creates addition console.log messages
	testMode: false, // good for jest testing
});

const App = ({ location }) => {
	// remember past location and only update if location changes
	const prevLocation = React.useRef('');

	React.useEffect(() => {
		if (prevLocation.current !== location.pathname) {
			ReactGA.pageview(location.pathname);
			window.scrollTo(0, 0);
			prevLocation.current = location.pathname;
		}
	}, [location]);

	return (
		<>
			<Header />
			<div className={style.PageContainer}>
				<Switch>
					<Route exact path="/">
						<Home />
					</Route>
					<Route path="/legal/:policy?">
						<Legal />
					</Route>
					<Route path="*">
						404
					</Route>
				</Switch>
			</div>
			<Footer />
		</>
	);
};

App.propTypes = {
	location: PropTypes.objectOf(PropTypes.any).isRequired
};

export default withRouter(App);
