import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';

// styles
import style from './index.module.css';

// Components
import Cookie from 'components/Policies/Cookie';
import Privacy from 'components/Policies/Privacy';
import Terms from 'components/Policies/Terms';

const Legal = ({ match }) => {
	const path = match.params.policy || false;

	if (!path) {
		return (
			<Redirect to="/legal/terms" />
		);
	}

	return (
		<div className={ style.Content }>
			{
				path === 'cookie'
					? <Cookie />
					: null
			}
			{
				path === 'privacy'
					? <Privacy />
					: null
			}
			{
				path === 'terms'
					? <Terms />
					: null
			}
		</div>
	);
};

Legal.propTypes = {
	match: PropTypes.objectOf(PropTypes.any).isRequired
};

export default withRouter(Legal);
