import React from 'react';
import ReactGA from 'react-ga';

// styles
import style from './index.module.css';

const Home = () => {
	const logDownloadClick = storeName => {
		ReactGA.event({
			category: 'User',
			action: 'Download App',
			label: storeName,
		});
	};

	return (
		<div className={style.Content}>
			<div className={style.Hero}>
				<div className={style.Canvas}>
					<div className={[style.ImageGroup, style.AndroidGroup].join(' ')}>
						<a
							href="https://play.google.com/store/apps/details?id=com.elementtheory.targetpace"
							onClick={() => logDownloadClick('Google Play Store, Screenshot')}
							target="_blank"
						>
							<img className={style.ScreenShot} src="/img/android-screenshot-2.png" alt="App Screenshot in Google Pixel" />
						</a>

						<a
							className={[style.BadgePlayStore, style.ScreenShotBadge].join(' ')}
							href="https://play.google.com/store/apps/details?id=com.elementtheory.targetpace"
							target="_blank"
							onClick={() => logDownloadClick('Google Play Store')}
						>
							<img src="/img/google-play-badge-dark.svg" alt="Download Link to Target Pace App in the Google Play Store" height="70" />
						</a>
					</div>
					<div className={[style.ImageGroup, style.IosGroup].join(' ')}>
						<a
							href="https://apps.apple.com/app/id1529655666"
							target="_blank"
							onClick={() => logDownloadClick('Apple App Store, Screenshot')}
						>
							<img className={style.ScreenShot} src="/img/ios-screenshot-1.png" alt="App Screenshot in App iPhone" />
						</a>
						<a
							className={[style.BadgeAppStore, style.ScreenShotBadge].join(' ')}
							href="https://apps.apple.com/app/id1529655666"
							target="_blank"
							onClick={() => logDownloadClick('Apple App Store')}
						>
							<img src="/img/app-store-badge-dark.svg" alt="Download Link to Target Pace App in the Apple App Store" height="50" />
						</a>
					</div>
				</div>
			</div>
			<div className={style.Badges}>
				<a
					className={style.BadgeAppStore}
					href="https://apps.apple.com/app/id1529655666"
					target="_blank"
					onClick={() => logDownloadClick('Apple App Store')}
				>
					<img src="/img/app-store-badge-dark.svg" alt="Download Link to Target Pace App in the Apple App Store" height="50" />
				</a>
				<a
					className={style.BadgePlayStore}
					href="https://play.google.com/store/apps/details?id=com.elementtheory.targetpace"
					target="_blank"
					onClick={() => logDownloadClick('Google Play Store')}
				>
					<img src="/img/google-play-badge-dark.svg" alt="Download Link to Target Pace App in the Google Play Store" height="70" />
				</a>
			</div>
			<div className={style.Attributions}>
				<p>Apple and Apple logo are trademarks of Apple Inc. Google Play and the Google Play logo are trademarks of Google LLC.</p>
			</div>
		</div>
	)
}

export default Home;
