import React from 'react';
import { Link } from 'react-router-dom';

const Privacy = () => (
	<div>
		<h1>Privacy Policy</h1>
		<p className="DateUpdated">
			<strong>Last Updated:</strong>  August 1, 2020
		</p>
		<p>
			Thank you for choosing to be part of our community at Target Pace, by Element Theory LLC
			("Company", "we", "us", or "our"). We are committed to protecting your personal information and
			your right to privacy. If you have any questions or concerns about this privacy policy, or our practices
			with regards to your personal information, please contact us at privacy@targetpace.com.
		</p>
		<p>
			When you visit our website <Link to="/">https://www.targetpace.com</Link>
			&nbsp;(the "Website"), use our mobile application, as the case may be (the "App") and more generally, use any
			of our services (the "Services", which include the Website and App), we appreciate that you are trusting
			us with your personal information. We take your privacy very seriously. In this privacy policy, we seek to
			explain to you in the clearest way possible what information we collect, how we use it and what rights you
			have in relation to it. If there are any terms in this privacy policy that you do not agree with, please
			discontinue use of our Services immediately.
		</p>
		<p>
			This privacy policy applies to all information collected through our Services (which, as described above,
			includes our Website and App), as well as any related services, sales, marketing or events.
		</p>
		<p>
			<strong>
				Please read this privacy policy carefully as it will help you understand what we do with the
				information that we collect.
			</strong>
		</p>
		<h2>
			Table of Contents
		</h2>
		<p>
			<a href="#infocollect">
				1. What information do we collect?
			</a>
		</p>
		<p>
			<a href="#infouse">
				2. How do we use your information?
			</a>
		</p>
		<p>
			<a href="#infoshare">
				3. Will your information be shared with anyone?
			</a>
		</p>
		<p>
			<a href="#whoshare">
				4. Who will your information be shared with?
			</a>
		</p>
		<p>
			<a href="#cookies">
				5. Do we use cookies and other tracking technologies?
			</a>
		</p>
		{/* <p>
			<a href="#googlemaps">
				6. Do we use Google Maps?
			</a>
		</p> */}
		<p>
			<a href="#sociallogins">
				6. How do we handle your social logins?
			</a>
		</p>
		<p>
			<a href="#thirdpartywebsites">
				7. What is our stance on third-party websites?
			</a>
		</p>
		<p>
			<a href="#inforetain">
				8. How long do we keep your information?
			</a>
		</p>
		<p>
			<a href="#privacyrights">
				9. What are your privacy rights?
			</a>
		</p>
		<p>
			<a href="#DNT">
				10. Controls for Do-Not-Track Features
			</a>
		</p>
		<p>
			<a href="#caresidents">
				11. Do California residents have specific privacy rights?
			</a>
		</p>
		<p>
			<a href="#policyupdates">
				12. Do we make updates to this policy?
			</a>
		</p>
		<p>
			<a href="#contact">
				13. How can you contact us about this policy?
			</a>
		</p>
		<h2 id="infocollect">
			1. What information do we collect?
		</h2>
		<h3>
			Personal information you disclose to us
		</h3>
		<p>
			<em>
				<strong>
					In Short:&nbsp;
				</strong>
				We collect information that you provide to us.
			</em>
		</p>
		<p>
			We collect personal information that you voluntarily provide to us when you register on the Services,
			express an interest in obtaining information about us or our Services, when you participate in
			activities on the Services (such as recording your workout activity) or otherwise when you contact us.
		</p>
		<p>
			The personal information that we collect depends on the context of your interactions with us and the
			Services, the choices you make and the features you use. The personal information we collect
			may include the following:
		</p>
		<p>
			<strong>
				Personal Information Provided by You.
			</strong>
			We collect names; email addresses; usernames; passwords;
			{/* phone numbers; contact preferences; billing
			addresses; debit/credit card numbers; contact or authentication data; */} and other similar information.
		</p>
		{/* <p>
			<strong>
				Payment Data.
			</strong>
			We may collect data necessary to process your payment if you make purchases, such as your payment instrument
			number (such as a credit card number), and the security code associated with your payment instrument. All
			payment data is stored by PayPal and Stripe. You may find their privacy policy link(s) here:
			<a target="_blank" href="https://www.paypal.com/us/webapps/mpp/ua/privacy-full">
				https://www.paypal.com/us/webapps/mpp/ua/privacy-full
			</a>
			and
			<a target="_blank" href="https://stripe.com/privacy">
				https://stripe.com/privacy
			</a>
			.
		</p> */}
		<p>
			<strong>
				Social Media Login Data.
			</strong>
			We may provide you with the option to register with us using your existing social media account details,
			like your Facebook, Twitter or other social media account. If you choose to register in this way, we will
			collect the information described in the section called "<a href="#sociallogins">HOW DO WE HANDLE
				YOUR SOCIAL LOGINS</a>" below.
		</p>
		<p>
			All personal information that you provide to us must be true, complete and accurate, and you must notify us
			of any changes to such personal information.
		</p>
		<h3>
			Information automatically collected
		</h3>
		<p>
			<em>
				<strong>
					In Short:&nbsp;
				</strong>
				Some information &mdash; such as your Internet Protocol (IP) address and/or browser and device
				characteristics &mdash; is collected automatically when you visit our Services.
			</em>
		</p>
		<p>
			We automatically collect certain information when you visit, use or navigate the Services. This information
			does not reveal your specific identity (like your name or contact information) but may include device and
			usage information, such as your IP address, browser and device characteristics, operating system, language
			preferences, referring URLs, device name, country, location, information about who and when you use our
			Services and other technical information. This information is primarily needed to maintain the security and
			operation of our Services, and for our internal analytics and reporting purposes.
		</p>
		<p>
			Like many businesses, we also collect information through cookies and similar technologies.
		</p>
		<p>
			The information we collect includes:
		</p>
		<ul>
			<li>
				<em>
					Log and Usage Data.
				</em>
				Log and usage data is service-related, diagnostic usage and performance information our servers
				automatically collect when you access or use our Services and which we record in log files. Depending on
				how you interact with us, this log data may include your IP address, device information, browser type
				and settings and information about your activity in the Services (such as the date/time stamps
				associated with your usage, pages and files viewed, searches and other actions you take such as which
				features you use), device event information (such as system activity, error reports (sometimes called
				'crash dumps') and hardware settings).
			</li>
		</ul>
		<ul>
			<li>
				<em>
					Device Data.
				</em>
				We collect device data such as information about your computer, phone, tablet or other device you use to
				access the Services. Depending on the device used, this device data may include information such as
				your IP address (or proxy server), device application identification numbers, location, browser type,
				hardware model, internet service provider, mobile carrier, and operating system
				configuration information.
			</li>
		</ul>
		<ul>
			<li>
				<em>
					Location Data.
				</em>
				We collect information data such as information about your device's location, which can be either
				precise or imprecise. How much information we collect depends on the type of settings of the device you
				use to access the Services. For example, we may use GPS and other technologies to collect geolocation
				data that tells us your current location (based on your IP address). You can opt out of allowing us to
				collect this information either by refusing access to the information or by disabling your Locations
				settings on your device. Note however, if you choose to opt out, you may not be able to use certain
				aspects of the Services.
			</li>
		</ul>
		<h3>
			Information collected through our App
		</h3>
		<p>
			<em>
				<strong>
					In Short:&nbsp;
				</strong>
				We collect information regarding your geo-location, mobile device, push notifications, when you use our
				App.
			</em>
		</p>
		<p>
			If you use our App, we also collect the following information:
		</p>
		<ul>
			<li>
				<em>
					Geo-Location Information.
				</em>
				We may request access or permission to and track location-based information from your mobile device,
				either continuously or while you are using our App, to provide certain location-based services. If you
				wish to change our access or permissions, you may do so in your device's settings.
			</li>
		</ul>
		<ul>
			<li>
				<em>
					Mobile Device Access.
				</em>
				We may request access or permission to certain features from your mobile device, including your mobile
				device's camera, contacts, sensors, sms messages, storage, and other features. If you wish to change our
				access or permissions, you may do so in your device's settings.
			</li>
		</ul>
		<ul>
			<li>
				<em>
					Mobile Device Data.
				</em>
				We automatically collect device information (such as your mobile device ID, model and manufacturer),
				operating system, version information and system configuration information, device and application
				identification numbers, browser type and version, hardware model, internet service provider and/or
				mobile
				carrier, and Internet Protocol (IP) address (or proxy server). If you are using our App, we may also
				collect information about the phone network associated with your mobile device, your mobile device's
				operating system or platform, the type of mobile device you use, your mobile device's unique device ID
				and information about the features of our App you accessed.
			</li>
		</ul>
		<ul>
			<li>
				<em>
					Push Notifications.
				</em>
				We may request to send you push notifications regarding your account or certain features of the App. If
				you wish to opt-out from receiving these types of communications, you may turn them off in your device's
				settings.
			</li>
		</ul>
		<p>
			The information is primarily needed to maintain the security and operation of our App, for troubleshooting
			and for our internal analytics and reporting purposes.
		</p>
		<h2 id="infouse">
			2. How do we use your information?
		</h2>
		<p>
			<em>
				<strong>
					In Short:&nbsp;
				</strong>
				We process your information for purposes based on legitimate business interests, the fulfillment of our
				contract with you, compliance with our legal obligations, and/or your consent.
			</em>
		</p>
		<p>
			We use personal information collected via our Services for a variety of business purposes described below.
			We process your personal information for these purposes in reliance on our legitimate business interests, in
			order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal
			obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.
		</p>
		<p>
			We use the information we collect or receive:
		</p>
		<ul>
			<li>
				<strong>
					To facilitate account creation and logon process.&nbsp;
				</strong>
				If you choose to link your account with us to a third-party account (such as your Google or
				Facebook account), we use the information you allowed us to collect from those third parties to
				facilitate account creation and logon process for the performance of the contract. See the section below
				headed "
				<a href="#sociallogins">
					HOW DO WE HANDLE YOUR SOCIAL LOGINS
				</a>
				" for further information.
			</li>
			<li>
				<strong>
					To post testimonials.&nbsp;
				</strong>
				We post testimonials on our Services that may contain personal information. Prior to posting a
				testimonial, we will obtain your consent to use your name and the consent of the testimonial. If you
				wish to update, or delete your testimonial, please contact us at privacy@targetpace.com and be sure to
				include your name, testimonial location, and contact information.
			</li>
			<li>
				<strong>
					Request feedback.&nbsp;
				</strong>
				We may use your information to request feedback and to contact you about your use of our Services.
			</li>
			<li>
				<strong>
					To enable user-to-user communications.&nbsp;
				</strong>
				We may use your information in order to enable user-to-user communications with each user's
				consent.
			</li>
			<li>
				<strong>
					To manage user accounts.&nbsp;
				</strong>
				We may use your information for the purposes of managing our account and keeping it in working order.
			</li>
			<li>
				<strong>
					To send administrative information to you.&nbsp;
				</strong>
				We may use your personal information to send you product, service and new feature information and/or
				information about changes to our terms, conditions, and policies.
			</li>
			<li>
				<strong>
					To protect our Services.&nbsp;
				</strong>
				We may use your information as part of our efforts to keep our Services safe and secure (for
				example, for fraud monitoring and prevention).
			</li>
			<li>
				<strong>
					To enforce our terms, conditions and policies for business purposes, to comply with legal and
					regulatory requirements or in connection with our contract.
				</strong>
			</li>
			<li>
				<strong>
					To respond to legal requests and prevent harm.&nbsp;
				</strong>
				If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine
				how to respond.
			</li>
			<li>
				<strong>
					Fulfill and manage your orders.&nbsp;
				</strong>
				We may use your information to fulfill and manage your orders, payments, returns, and exchanges
				made through the Services.
			</li>
			<li>
				<strong>
					To deliver and facilitate delivery of services to the user.&nbsp;
				</strong>
				We may use your information to provide you with the requested service.
			</li>
			<li>
				<strong>
					To respond to user inquiries/offer support to users.&nbsp;
				</strong>
				We may use your information to respond to your inquiries and solve any potential issues you might have
				with the use of our Services.
			</li>
			<li>
				<strong>
					To send you marketing and promotional communications.&nbsp;
				</strong>
				We and/or our third-party marketing partners may use the personal information you send to us for
				our marketing purposes, if this is in accordance with your marketing preferences. For example, when
				expressing an interest in obtaining information about us or our Services, subscribing to marketing or
				otherwise contacting us, we will collect personal information from you. You can opt-out of our marketing
				emails at any time (see the "
				<a href="#privacyrights">
					WHAT ARE YOUR PRIVACY RIGHTS
				</a>
				" below).
			</li>
			<li>
				<strong>
					Deliver targeted advertising to you.&nbsp;
				</strong>
				We may use your information to develop and display personalized content and advertising (and work with
				third parties who do so) tailored to your interests and/or location and to measure its effectiveness.
			</li>
		</ul>
		<ul>
			<li>
				<strong>
					For other business purposes.&nbsp;
				</strong>
				We may use your information for other business purposes, such as data analysis, identifying usage
				trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our
				Services, products, marketing and your experience. We may use and store this information in
				aggregated and anonymized form so that it is not associated with individual end users and does not
				include personal information. We will not use identifiable personal information without your consent.
			</li>
		</ul>
		<h2 id="infoshare">
			3. Will your information be shared with anyone?
		</h2>
		<p>
			<em>
				<strong>
					In Short:&nbsp;
				</strong>
				We only share information with your consent, to comply with laws, to provide you with services, to
				protect your rights, or to fulfill business obligations.
			</em>
		</p>
		<p>
			We may process or share your data that we hold based on the following legal basis:
		</p>
		<ul>
			<li>
				<strong>
					Consent:&nbsp;
				</strong>
				We may process your data if you have given us specific consent to use your personal information in a
				specific purpose.
			</li>
			<li>
				<strong>
					Legitimate Interests:&nbsp;
				</strong>
				We may process your data when it is reasonably necessary to achieve our legitimate business interests.
			</li>
			<li>
				<strong>
					Performance of a Contract:&nbsp;
				</strong>
				Where we have entered into a contract with you, we may process your personal information to fulfill the
				terms of our contract.
			</li>
			<li>
				<strong>
					Legal Obligations:&nbsp;
				</strong>
				We may disclose your information where we are legally required to do so in order to comply with
				applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in
				response to a court order or a subpoena (including in response to public authorities to meet national
				security or law enforcement requirements).
			</li>
			<li>
				<strong>
					Vital Interests:&nbsp;
				</strong>
				We may disclose your information where we believe it is necessary to investigate, prevent, or take
				action regarding potential violations of our policies, suspected fraud, situations involving potential
				threats to the safety of any person and illegal activities, or as evidence in litigation in which we are
				involved.
			</li>
		</ul>
		<p>
			More specifically, we may need to process your data or share your personal information in the following
			situations:
		</p>
		<ul>
			<li>
				<strong>
					Business Transfers.&nbsp;
				</strong>
				We may share or transfer your information in connection with, or during negotiations of, any
				merger, sale of company assets, financing, or acquisition of all or a portion of our business to another
				company.
			</li>
		</ul>
		<ul>
			<li>
				<strong>
					Vendors, Consultants and Other Third-Party Service Providers.&nbsp;
				</strong>
				We may share your data with third-party vendors, service providers, contractors or agents who
				perform services for us or on our behalf and require access to such information to do that work.
				Examples include: payment processing, data analysis, email delivery, hosting services, customer service
				and marketing efforts. We may allow selected third parties to use tracking technology on the Services,
				which will enable them to collect data on our behalf about how you interact with our Services over time.
				This information may be used to, among other things, analyze and track data, determine the popularity of
				certain content, pages or features, and better understand online activity. Unless described in this
				policy, we do not share, sell, rent or trade any of your information with third parties for their
				promotional purposes.
			</li>
		</ul>
		<ul>
			<li>
				<strong>
					Affiliates.&nbsp;
				</strong>
				We may share your information with our affiliates, in which case we will require those affiliates
				to honor this privacy policy. Affiliates include our parent company and any subsidiaries, joint venture
				partners or other companies that we control or that are under common control with us.
			</li>
		</ul>
		<ul>
			<li>
				<strong>
					Business Partners.&nbsp;
				</strong>
				We may share your information with our business partners to offer you certain products, services
				or promotions.
			</li>
		</ul>
		<ul>
			<li>
				<strong>
					Other Users.&nbsp;
				</strong>
				When you share personal information (for example, by posting workout progress, contributions or other
				content to the Services) or otherwise interact with public areas of the Services, such personal
				information may be viewed by all users and may be publicly made available outside the Services in
				perpetuity. If you interact with other users of our Services and register for our Services through a
				social network (such as Facebook), your contacts on the social network will see your name, profile
				photo, and descriptions of your activity. Similarly, other users will be able to view descriptions of
				your activity, communicate with you within our Services, and view your profile.
			</li>
		</ul>
		<h2 id="whoshare">
			4. Who will your information be shared with?
		</h2>
		<p>
			<em>
				<strong>
					In Short:&nbsp;
				</strong>
				We only share information with third parties to improve our Services.
			</em>
		</p>
		<p>
			We may share your information with third parties who provide services to Target Pace such as supporting,
			improving, promoting and securing the Services, processing payments, or fulfilling orders. These
			service providers only have access to the information necessary to perform these limited functions
			on our behalf and are required to protect and secure your information. We may also engage service
			providers to collect information about your use of the Services over time on our behalf so that we
			or they may promote Target Pace or display information that may be relevant to your interests on the
			Services or other websites or services.
		</p>

		<h2 id="cookies">
			5. Do we use cookies and other tracking technologies?
		</h2>
		<p>
			<em>
				<strong>
					In Short:&nbsp;
				</strong>
				We may use cookies and other tracking technologies to collect and store your information.
			</em>
		</p>
		<p>
			We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
			information. Specific information about how we use such technologies and how you can refuse certain cookies
			is set out in our <Link to="/legal/cookie">Cookie Policy</Link>.
		</p>
		{/* <h2 id="googlemaps">
			6. Do we use Google Maps?
		</h2>
		<p>
			<em>
				<strong>
					In Short:&nbsp;
				</strong>
				Yes, we use Google Maps for the purpose of providing better service.
			</em>
		</p>
		<p>
			This Website or App uses Google Maps APIs which is subject to Google's Terms of Service. You may find the
			Google Maps APIs Terms of Service
			<a target="_blank" href="https://developers.google.com/maps/terms">
				here
			</a>
			. To find out more about Google&rsquo;s Privacy Policy, please refer to this
			<a target="_blank" href to="https://policies.google.com/privacy">
				link
			</a>
			. We obtain and store on your device ('cache') your location. You may revoke your consent anytime by
			contacting us at the contact details provided at the end of this document.
		</p> */}
		<h2 id="sociallogins">
			6. How do we handle your social logins?
		</h2>
		<p>
			<em>
				<strong>
					In Short:&nbsp;
				</strong>
				If you choose to register or log in to our services using a social media account, we may have access to
				certain information about you.
			</em>
		</p>
		<p>
			Our Services offers you the ability to register and login using your third-party social media account
			details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile
			information about you from your social media provider. The profile Information we receive may vary depending
			on the social media provider concerned, but will often include your name, email address, friends list,
			profile picture as well as other information you choose to make public on such social media platform.
		</p>
		<p>
			We will use the information we receive only for the purposes that are described in this privacy
			policy or that are otherwise made clear to you on the relevant Services. Please note that we do not
			control, and are not responsible for, other uses of your personal information by your third-party social
			media provider. We recommend that you review their privacy policy to understand how they collect, use and
			share your personal information, and how you can set your privacy preferences on their sites and apps.
		</p>
		<h2 id="thirdpartywebsites">
			7. What is our stance on third-party websites?
		</h2>
		<p>
			<em>
				<strong>
					In Short:&nbsp;
				</strong>
				We are not responsible for the safety of any information that you share with third-party providers who
				advertise, but are not affiliated with, our Services.
			</em>
		</p>
		<p>
			The Services may contain advertisements from third parties that are not affiliated with us and which
			may link to other websites, online services or mobile applications. We cannot guarantee the safety and
			privacy of data you provide to any third parties. Any data collected by third parties is not covered by this
			privacy policy. We are not responsible for the content or privacy and security practices and policies of any
			third parties, including other websites, services or applications that may be linked to or from the
			Services. You should review the policies of such third parties and contact them directly to respond to your
			questions.
		</p>
		<h2 id="inforetain">
			8. How long do we keep your information?
		</h2>
		<p>
			<em>
				<strong>
					In Short:&nbsp;
				</strong>
				We keep your information for as long as necessary to fulfill the purposes outlined in this privacy
				policy unless otherwise required by law.
			</em>
		</p>
		<p>
			We will only keep your personal information for as long as it is necessary for the purposes set out in this
			privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or
			other legal requirements). No purpose in this policy will require us keeping your personal information for
			longer than the period of time in which users have an account with us.
		</p>
		<p>
			When we have no ongoing legitimate business need to process your personal information, we will either delete
			or anonymize such information, or, if this is not possible (for example, because your personal information
			has been stored in backup archives), then we will securely store your personal information and isolate it
			from any further processing until deletion is possible.
		</p>
		<h2 id="privacyrights">
			9. What are your privacy rights?
		</h2>
		<p>
			<em>
				<strong>
					In Short:&nbsp;
				</strong>
				You may review, change, or terminate your account at any time.
			</em>
		</p>
		<p>
			If you are resident in the European Economic Area and you believe we are unlawfully processing your personal
			information, you also have the right to complain to your local data protection supervisory authority. You
			can find their contact details here:&nbsp;
			<a target="_blank" href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
				http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
			</a>
			.
		</p>
		<p>
			If you are resident in Switzerland, the contact details for the data protection authorities are available
			here:&nbsp;
			<a target="_blank" href="https://www.edoeb.admin.ch/edoeb/en/home.html">
				https://www.edoeb.admin.ch/edoeb/en/home.html
			</a>
			.
		</p>
		<p>
			If you have questions or comments about your privacy rights, you may email us at privacy@targetpace.com.
		</p>
		<h3>
			Account Information
		</h3>
		<p>
			If you would at any time like to review or change the information in your account or terminate your account,
			you can:
		</p>
		<ul>
			<li>Log in to your account settings and update your user account.</li>
		</ul>
		<p>
			Upon your request to terminate your account, we will deactivate or delete your account and information from
			our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot
			problems, assist with any investigations, enforce our Terms of Use and/or comply with applicable legal
			requirements.
		</p>
		<p>
			<strong>
				<u>Cookies and similar technologies:</u>&nbsp;
			</strong>
			Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your
			browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this
			could affect certain features or services of our Services. To opt-out of interest-based advertising by
			advertisers on our Services visit:&nbsp;
			<a target="_blank" href="http://www.aboutads.info/choices/">
				http://www.aboutads.info/choices/
			</a>
			.
		</p>
		<p>
			<strong>
				<u>Opting out of email marketing:</u>&nbsp;
			</strong>
			You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the
			emails that we send or by contacting us using the details provided below. You will then be removed from the
			marketing email list &ndash; however, we may still communicate with you, for example to send you
			service-related emails that are necessary for the administration and use of your account, to respond to
			service requests, or for other non-marketing purposes. To otherwise opt-out, you may:
		</p>
		<ul>
			<li>Access your account settings and update your preferences.</li>
		</ul>
		<h2 id="DNT">
			10. Controls for Do-Not-Track Features
		</h2>
		<p>
			Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track
			("DNT") feature or setting you can activate to signal your privacy preference not to have data
			about your online browsing activities monitored and collected. At this stage, no uniform technology standard
			for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT
			browser signals or any other mechanism that automatically communicates your choice not to be tracked online.
			If a standard for online tracking is adopted that we must follow in the future, we will inform you about
			that practice in a revised version of this privacy policy.
		</p>
		<h2 id="caresidents">
			11. Do California residents have specific privacy rights?
		</h2>
		<p>
			<em>
				<strong>
					In Short:&nbsp;
				</strong>
				Yes, if you are a resident of California, you are granted specific rights regarding access to your
				personal information.
			</em>
		</p>
		<p>
			California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our
			users who are California residents to request and obtain from us, once a year and free of charge,
			information about categories of personal information (if any) we disclosed to third parties for direct
			marketing purposes and the names and addresses of all third parties with which we shared personal
			information in the immediately preceding calendar year. If you are a California resident and would like to
			make such a request, please submit your request in writing to us using the contact information provided
			below.
		</p>
		<p>
			If you are under 18 years of age, reside in California, and have a registered account with a Service, you
			have the right to request removal of unwanted data that you publicly post on the Services. To request
			removal of such data, please contact us using the contact information provided below, and include the email
			address associated with your account and a statement that you reside in California. We will make sure the
			data is not publicly displayed on the Services, but please be aware that the data may not be completely or
			comprehensively removed from all our systems (e.g. backups, etc.).
		</p>
		<h3>
			CCPA Privacy Notice
		</h3>
		<p>
			The California Code of Regulations defines a "resident" as:
		</p>
		<p>
			(1) every individual who is in the State of California for other than a temporary or transitory purpose and
		</p>
		<p>
			(2) every individual who is domiciled in the State of California who is outside the State of California for
			a temporary or transitory purpose
		</p>
		<p>
			All other individuals are defined as "non-residents."
		</p>
		<p>
			If this definition of "resident" applies to you, certain rights and obligations apply regarding your
			personal information.
		</p>
		<h3>
			What categories of personal information do we collect?
		</h3>
		<p>
			We have collected the following categories of personal information in the past twelve (12) months:
		</p>
		<table>
			<tbody>
				<tr>
					<td>
						<strong>
							Category
						</strong>
					</td>
					<td>
						<strong>
							Examples
						</strong>
					</td>
					<td>
						<strong>
							Collected
						</strong>
					</td>
				</tr>
				<tr>
					<td>
						A. Identifiers
					</td>
					<td>
						Contact details such as real name, postal address, telephone or mobile contact number, email
						address and account name, if you choose to provide it. Non-contact details such as unique
						personal identifier, online identifier, and Internet Protocol address when interacting with the
						Service
					</td>
					<td>
						Yes
					</td>
				</tr>
				<tr>
					<td>
						B. Personal information categories listed in the California Customer Records statute
					</td>
					<td>
						Name, contact information, and financial information, if you choose to provide it
					</td>
					<td>
						Yes
					</td>
				</tr>
				<tr>
					<td>
						C. Protected classification characteristics under California or federal law
					</td>
					<td>
						Gender and date of birth, if you choose to provide it
					</td>
					<td>
						Yes
					</td>
				</tr>
				<tr>
					<td>
						D. Commercial information
					</td>
					<td>
						Transaction information, purchase history, financial details and payment information, if you
						choose to make purchases in our Service
					</td>
					<td>
						Yes
					</td>
				</tr>
				<tr>
					<td>
						E. Biometric information
					</td>
					<td>
						Fingerprints and voiceprints
					</td>
					<td>
						No
					</td>
				</tr>
				<tr>
					<td>
						F. Internet or other similar network activity
					</td>
					<td>
						Browsing history, search history, online behavior, interest data, and interactions with our and
						other websites, applications, systems and advertisements
					</td>
					<td>
						Yes
					</td>
				</tr>
				<tr>
					<td>
						G. Geolocation data
					</td>
					<td>
						Location-related information, including precise device location, if you choose to allow us to
						collect it
					</td>
					<td>
						Yes
					</td>
				</tr>
				<tr>
					<td>
						H. Audio, electronic, visual, thermal, olfactory, or similar information
					</td>
					<td>
						Audio or visual information, including photos and videos, if you choose to provide it
					</td>
					<td>
						Yes
					</td>
				</tr>
				<tr>
					<td>
						I. Professional or employment-related information
					</td>
					<td>
						Business contact details in order to provide you our services at a business level, job title as
						well as work history and professional qualifications if you apply for a job with us
					</td>
					<td>
						No
					</td>
				</tr>
				<tr>
					<td>
						J. Education Information
					</td>
					<td>
						Student records and directory information
						<br />
					</td>
					<td>
						No
					</td>
				</tr>
				<tr>
					<td>
						K. Inferences drawn from other personal information
					</td>
					<td>
						Inferences drawn from any of the collected personal information listed above to create a profile
						or summary about, for example, an individual&rsquo;s preferences and characteristics
						<br />
					</td>
					<td>
						Yes
					</td>
				</tr>
			</tbody>
		</table>
		<p>
			We may also collect other personal information outside of these categories in instances where you interact
			with us in-person, online, or by phone or mail in the context of:
		</p>
		<ul>
			<li>
				Receiving help through our customer support channels
			</li>
			<li>
				Participation in customer surveys or contests; and
			</li>
			<li>
				Facilitation in the delivery of our Services and to respond to your inquiries
			</li>
		</ul>
		<h3>
			How do we use and share your personal information?
		</h3>
		<p>
			Target Pace collects and shares your personal information through:
		</p>
		<ul>
			<li>
				Beacons/Pixels/Tags
			</li>
		</ul>
		<p>
			More information about our data collection and sharing practices can be found in this privacy policy.
		</p>
		<p>
			You may contact us by email at privacy@targetpace.com, or by referring to the contact details at the bottom
			of this document.
		</p>
		<p>
			If you are using an authorized agent to exercise your right to opt-out, we may deny a request if the
			authorized agent does not submit proof that they have been validly authorized to act on your behalf.
		</p>
		<h3>
			Will your information be shared with anyone else?
		</h3>
		<p>
			We may disclose your personal information with our service providers pursuant to a written contract between
			us and each service provider. Each service provider is a for-profit entity that processes the information on
			our behalf.
		</p>
		<p>
			The current list of our service providers can be found below.
		</p>
		<p>
			We may use your personal information for our own business purposes, such as for undertaking internal
			research for technological development and demonstration. This is not considered to be "selling" of your
			personal data.
		</p>
		<p>
			Target Pace has disclosed the following categories or personal information to third parties for a business
			or commercial purpose in the preceding twelve (12) months:
		</p>
		<ul>
			<li>
				Category A. Identifiers, such as contact details, like your real name, alias, postal address, telephone
				or mobile contact number, unique personal identifier, online identifier, Internet Protocol address,
				email address and account name.
			</li>
		</ul>
		<ul>
			<li>
				Category B. Personal information, as defined in the California Customer Records law, such as your name,
				contact information, education, employment, employment history and financial information.
			</li>
		</ul>
		<ul>
			<li>
				Category H. Audio, electronic, visual and similar information, such as images and audio, video or call
				recordings created in connection with our business activities.
			</li>
		</ul>
		<p>
			The categories of third parties to whom we disclosed personal information for a business or commercial
			purpose can be found under "<a href="#whoshare">Who will your information be shared with?</a>".
		</p>
		<h3>
			Your rights with respect to your personal data
		</h3>
		<p>
			<u>
				Right to request deletion of the data - Request to delete
			</u>
		</p>
		<p>
			You can ask for the deletion of your personal information. If you ask us to delete your personal
			information, we will respect your request and delete your personal information, subject to certain
			exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her
			right to free speech, our compliance requirements resulting from a legal obligation or any processing that
			may be required to protect against illegal activities.
		</p>
		<p>
			<u>
				Right to be informed - Request to know
			</u>
		</p>
		<p>
			Depending on the circumstances, you have a right to know:
		</p>
		<ul>
			<li>whether we collect and use your personal information;</li>
			<li>the categories of personal information that we collect;</li>
			<li>the purposes for which the collected personal information is used;</li>
			<li>whether we sell your personal information to third parties;</li>
			<li>the categories of personal information that we sold or disclosed for a business purpose;</li>
			<li>the categories of third parties to whom the personal information was sold or disclosed for a business purpose; and</li>
			<li>the business or commercial purpose for collecting or selling personal information.</li>
		</ul>
		<p>
			In accordance with applicable law, we are not obligated to provide or delete consumer information that is
			de-identified in response to a consumer request or to re-identify individual data to verify a consumer
			request.
		</p>
		<p>
			<u>
				Right to Non-Discrimination for the Exercise of a Consumer's Privacy Rights
			</u>
		</p>
		<p>
			We will not discriminate against you if you exercise your privacy rights.
		</p>
		<p>
			<u>
				Verification process
			</u>
		</p>
		<p>
			Upon receiving your request, we will need to verify your identity to determine you are the same person about
			whom we have the information in our system. These verification efforts require us to ask you to provide
			information so that we can match it with the information you have previously provided us. For instance,
			depending on the type of request you submit, we may ask you to provide certain information so that we can
			match the information you provide with the information we already have on file, or we may contact you
			through a communication method (e.g. phone or email) that you have previously provided to us. We may also
			use other verification methods as the circumstances dictate.
		</p>
		<p>
			We will only use personal information provided in your request to verify your identity or authority to make
			the request. To the extent possible, we will avoid requesting additional information from you for the
			purposes of verification. If, however, if we cannot verify your identity from the information already
			maintained by us, we may request that you provide additional information for the purposes of verifying your
			identity, and for security or fraud-prevention purposes. We will delete such additionally provided
			information as soon as we finish verifying you.
		</p>
		<p>
			<u>
				Other privacy rights
			</u>
		</p>
		<ul>
			<li>you may object to the processing of your personal data</li>
			<li>you may request correction of your personal data if it is incorrect or no longer relevant, or ask to
			restrict the processing of the data</li>
			<li>you can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a
			request from an authorized agent that does not submit proof that they have been validly authorized
			to act on your behalf in accordance with the CCPA.</li>
			<li>you may request to opt-out from future selling of your personal information to third parties. Upon
			receiving a request to opt-out, we will act upon the request as soon as feasibly possible, but no
			later than 30 days from the date of the request submission.</li>
		</ul>
		<p>
			To exercise these rights, you can contact us by email at privacy@targetpace.com, or by referring to the
			contact details at the bottom of this document. If you have a complaint about how we handle your data, we
			would like to hear from you.
		</p>
		<h2 id="policyupdates">
			12. Do we make updates to this policy?
		</h2>
		<p>
			<em>
				<strong>
					In Short:&nbsp;
				</strong>
				Yes, we will update this policy as necessary to stay compliant with relevant laws.
			</em>
		</p>
		<p>
			We may update this privacy policy from time to time. The updated version will be indicated by an
			"Last Updated" date and the updated version will be effective as soon as it is accessible. If
			we make material changes to this privacy policy, we may notify you either by prominently posting a notice of
			such changes or by directly sending you a notification. We encourage you to review this privacy
			policy frequently to be informed of how we are protecting your information.
		</p>
		<h2 id="contact">
			13. How can you contact us about this policy?
		</h2>
		<p>
			If you have questions or comments about this policy, you may email us at:
		</p>
		<p>
			support@targetpace.com
		</p>
		<p>with the subject line: "Target Pace: Privacy Policy".</p>
		{/* <h3>
			HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
		</h3>
		<p>
			Based on the applicable laws of your country, you may have the right to request access to the personal
			information we collect from you, change that information, or delete it in some circumstances.
		</p>
		<p>You may correct or update you profile or account information, along with deleting your account at any time by adjusting that information
			in your account settings. If you have any additional questions, please email us at privacy@targetpace.com.
			We will respond to your request within 30 days.
		</p> */}
	</div>
);

export default Privacy;
