import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

// Style
import style from './index.module.css';

const Header = ({ history }) => (
	<div className={style.Header}>
		<div className={style.HeaderWrap}>
			<Link className={style.Logo} to="/">
				<img src="/target-pace-logo.svg" alt="Target Pace Logo" width="34" height="34" />
				<span>Target Pace</span>
			</Link>
		</div>
	</div>
);

Header.propTypes = {
	history: PropTypes.objectOf(PropTypes.any).isRequired
};

export default withRouter(Header);
